<template>
  <v-dialog
    transition="dialog-bottom-transition"
    max-width="350"
    v-model="dialog"
    persistent
  >
    <v-container class="background rounded-lg">
      <v-row justify="end">
        <v-icon class="ma-0 pa-0 mt-2 mr-2" color="primary" @click="modalAction"
          >mdi-close</v-icon
        ></v-row
      >
      <v-row class="ma-0"
        ><v-col class="text-center">{{ $t("expert.add_files") }}</v-col></v-row
      ><v-row class="ma-0 mb-4"><v-divider class="mx-4"></v-divider></v-row>
      <v-row class="pa-0 ma-0" justify="center">
        <v-col cols="10" sm="8" md="8" class="pa-0">
          <v-btn
            color="secondary"
            block
            outlined
            large
            rounded
            @click="addExpertFile"
            style="text-transform: none"
          >
            {{ $t("expert.add_files") }}
            <v-icon right dark> mdi-paperclip </v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row class="ma-0 pa-0">
        <v-file-input
          small-chips
          show-size
          :class="expertFile != null ? '' : 'd-none'"
          id="expertFile"
          label="expertFile"
          truncate-length="15"
          v-model="expertFile"
          accept=".pdf,.doc,.docx"
        ></v-file-input>
      </v-row>
      <v-row justify="end" class="ma-0 mt-2"
        ><v-btn
          color="secondary"
          class="rounded-lg"
          large
          @click="changeExpertLogos"
          >{{ $t("expert.add") }}</v-btn
        ></v-row
      >
    </v-container>
  </v-dialog>
</template>
<script>
import CandidateService from "@/services/CandidateService.js";
import { mapGetters, mapActions } from "vuex";
export default {
  props: {
    dialog: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      expertFile: null,
    };
  },
  computed: {
    ...mapGetters({ childId: "user/childId" }),
  },
  methods: {
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    modalAction() {
      this.$emit("close");
    },
    async changeExpertLogos() {
      this.enableLoading();
      try {
        await CandidateService.uploadExpertFiles(
          this.$route.params.candidate_id,
          this.$route.query.offer_id,
          this.expertFile
        );
        this.$notify({
          title: this.$i18n.t("notification.add_expert_file"),
          text: this.$i18n.t("notification.add_expert_file"),
          type: "success",
        });
        this.modalAction();
        //TODO not reload page, update candidate vue variable whit the URL given in call to API
        setTimeout(function () {
          location.reload();
        }, 500);
        this.$emit('markAsSelected')
      } catch (error) {

        this.$notify({
          title: this.$i18n.t("notification.add_expert_file_error"),
          text: this.$i18n.t("notification.add_expert_file_error"),
          type: "error",
        });
      } finally {
        this.disableLoading();
      }
    },
    addExpertFile() {
      document.getElementById("expertFile").click();
    },
  },
};
</script>
<style scoped>
.background {
  background-color: white;
  background-image: url("~@/assets/apotalent/background.svg");
  background-repeat: no-repeat;
  background-position: bottom;
}
</style>
