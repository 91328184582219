<template>
  <v-dialog transition="dialog-bottom-transition" max-width="350" v-model="dialog" persistent>
    <v-container class="background rounded-lg">
      <v-row justify="end">
        <v-icon class="ma-0 pa-0 mt-2 mr-2" color="primary" @click="modalAction(false)"
          >mdi-close</v-icon
        ></v-row
      >
      <v-row class="ma-0"
        ><v-col class="text-center">{{ $t("note.add") }}</v-col></v-row
      ><v-row class="ma-0 mb-4"><v-divider class="mx-4"></v-divider></v-row>
      <v-row class="ma-0 pa-0"
        ><v-textarea
          label="Introduce tu comentario"
          auto-grow
          outlined
          v-model="textValue"
        ></v-textarea
      ></v-row>
      <v-row justify="end" class="ma-0"
        ><v-btn
          color="secondary"
          class="rounded-lg"
          large
          @click="modalAction(true)"
          >{{ $t("operation.save") }}</v-btn
        ></v-row
      >
    </v-container>
  </v-dialog>
</template>
<script>
export default {
  props: {
    dialog: {
      default: false,
      type: Boolean
    },
    text: {
      default: null,
      type: String
    }
  },
  computed:{
    textValue:{
      get(){
        return this.notes || this.text
      },
      set(val){
        this.notes=val
      }
    }
  },
  data() {
    return {
      modal: null,
      notes:null,
    };
  },
  mounted() {
    this.modal = this.dialog;
  },
  methods: {
    modalAction(update) {
      this.$emit("close",{text:this.notes,update:update});
    }
  }
};
</script>
<style scoped>
.background {
  background-color: white;
  background-image: url("~@/assets/apotalent/background.svg");
  background-repeat: no-repeat;
  background-position: bottom;
}
</style>
