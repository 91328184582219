<template>
  <v-container v-if="mounted">
    <layout :back="true" />
    <add-notes :dialog.sync="dialog" :text="notes" @close="modalAction" />
    <add-expert-files
      :dialog.sync="expertFilesModal"
      @close="toggleExpertFiles"
      @markAsSelected="markAsSelected"
    />
    <pdf-viewer
      v-if="renderPdf != null"
      @closePdfViewer="closePdfViewer"
      :download="wantDownload"
      :showPdfViewer.sync="pdfDialog"
      :pdf-path="renderPdf"
    />
    <video-player
      @closeVideoPlayer="toggleVideoPlayer"
      :showVideoPlayer.sync="videoPlayer"
      :src="candidate.video"
    />
    <other-files @closeModal="toggleModal" :openModal.sync="showotherFiles" />
    <v-col>
      <v-row class="text-caption ml-3 mt-1" justify-sm="center">
        <v-col class="pa-0" cols="12" sm="8" md="5">
          <span class="primary--text ml-2">{{ $tc("offer", 1) }}</span
          ><span class="font-weight-light">{{ candidate.title.name }}</span>
        </v-col></v-row
      >

      <v-row justify-sm="center">
        <v-col class="pa-0" cols="12" sm="8" md="5">
          <v-list-item class="ml-2">
            <v-list-item-avatar tile size="80">
              <v-img
                alt="user avatar"
                class="shrink"
                contain
                :src="candidate.avatar"
                transition="scale-transition"
            /></v-list-item-avatar>
            <v-list-item-content>
              <v-col cols="12" class="pa-0 ma-0"
                ><div class="text-subtitle-1 primary--text mb-1">
                  {{ candidate.full_name }}
                </div>

                <v-menu left bottom offset-y>
                  <template v-slot:activator="{ on, attrs }" class="customlist">
                    <v-chip
                      v-bind="attrs"
                      v-on="on"
                      small
                      :color="status.color"
                      text-color="white"
                      >{{ status.text }}
                      <v-icon right> mdi-menu-down </v-icon>
                    </v-chip>
                  </template>
                  <!-- Profile dropdown list -->
                  <v-list class="rounded-lg" color="white">
                    <v-row
                      dense
                      v-for="(status, index) in pharmacyStatus"
                      :key="index"
                    >
                      <v-list-item class="ma-0 pa-0 text-center">
                        <v-list-item-title
                          class="font-weight-light secondary--text"
                        >
                          <v-chip
                            small
                            @click="updateStatus(status[0])"
                            :color="colors[index]"
                            class="mx-2"
                            text-color="white"
                            >{{ status[1] }}
                          </v-chip>
                        </v-list-item-title>
                      </v-list-item>
                    </v-row>
                  </v-list>
                </v-menu>
              </v-col>
              <!-- <v-col cols="2" class="mb-5 pa-0 ma-0 text-end">
                <v-icon> mdi-dots-vertical </v-icon>
              </v-col> -->

              <div></div>

              <div class="mt-3">
                <v-btn
                  v-if="candidate.curriculum"
                  class="pa-0 mx-1 mb-1"
                  @click="openCurriculumModal()"
                  color="primary"
                >
                  <img src="@/assets/apotalent/cv.svg" />
                </v-btn>
                <v-btn
                  v-if="otherFiles.length > 0"
                  class="pa-0 mx-1 mb-1"
                  color="primary"
                  @click="showotherFiles = true"
                >
                  <v-icon> mdi-folder-multiple </v-icon>
                </v-btn>
                <v-btn
                  class="pa-0 mx-1 mb-1"
                  color="primary"
                  @click="dialog = true"
                >
                  <v-icon> mdi-notebook </v-icon>
                </v-btn>
                <!-- <v-btn class="pa-0 mx-2 mb-1" color="primary">
                  <v-icon> mdi-email </v-icon>
                </v-btn> -->
                <v-btn
                  v-if="candidate.video"
                  class="pa-0 mx-1 mb-1"
                  color="primary"
                  @click="toggleVideoPlayer"
                >
                  <v-icon> mdi-play-circle </v-icon>
                </v-btn>
                <v-dialog
                  v-model="contactInfo"
                  @click:outside="contactInfo = false"
                  transition="dialog-bottom-transition"
                  max-width="600"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="pa-0 mx-1 mb-1"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon> mdi-email </v-icon>
                    </v-btn>
                  </template>
                  <div>
                    <v-card>
                      <v-toolbar color="primary" dark
                        >{{ $t("field.contact_info") }}
                      </v-toolbar>
                      <v-card-text>
                        <v-list>
                          <v-list-item>
                            <span class="mr-1">{{
                              $t("field.contact_phone_number") + `:`
                            }}</span>
                            <a :href="'tel:' + candidate.phone_number">{{
                              candidate.phone_number
                            }}</a>
                          </v-list-item>
                          <v-list-item>
                            <span class="mr-1">{{
                              $t("field.contact_email") + `:`
                            }}</span>
                            <a :href="'mailto: ' + candidate.user.email">{{
                              candidate.user.email
                            }}</a>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                      <v-card-actions class="justify-end">
                        <v-btn text @click="contactInfo = false">
                          {{ $t("field.close") }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </div>
                </v-dialog>
                <v-btn
                  v-if="
                    (userType == 'pharmacy' && expertFiles.length > 0) ||
                      userType == 'expert'
                  "
                  class="pa-0 mx-1 mb-1"
                  :color="expertFiles.length > 0 ? 'success' : 'error'"
                  @click="
                    userType == 'pharmacy'
                      ? openExpertFilesModal()
                      : userType == 'expert'
                      ? toggleExpertFiles()
                      : ''
                  "
                >
                  <v-icon> mdi-file-search </v-icon>
                </v-btn>
              </div>
            </v-list-item-content>
          </v-list-item></v-col
        ></v-row
      >
    </v-col>
    <!-- <v-row justify-sm="center">
        <v-col cols="10" md="6" sm="8" class="ma-0 pa-0 primary--text">
          <v-list-item class="primary--text ml-3">
            <v-list-item-content>
              <v-list-item-title class="primary--text">
                {{ $t("candidate.studies") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row class="ma-0" justify-sm="center">
        <v-col cols="12" md="6" sm="8" class="pa-0 mt-1">
          <v-divider class="mx-4"></v-divider>
        </v-col>
      </v-row>
      <v-row class="pa-0 ml-4" justify-sm="center">
        <v-col cols="12" md="6" sm="8" class="pa-0">
          <v-list-item class="ml-2 ma-0 pa-0">
            <v-list-item-avatar
              tile
              size="30"
              color="grey"
            ></v-list-item-avatar>
            <v-list-item-content class="pa-0">
              <v-list-item-title
                class="mb-1 text-caption primary--text font-weight-bold"
              >
                {{ candidate.title.name }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row justify-sm="center" v-if="candidate.expertises.length > 0">
        <v-col cols="10" md="6" sm="8" class="ma-0 pa-0 primary--text">
          <v-list-item class="primary--text ml-3">
            <v-list-item-content>
              <v-list-item-title class="primary--text">
                {{ $t("candidate.last_position") }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <v-row
        class="ma-0"
        justify-sm="center"
        v-if="candidate.expertises.length > 0"
      >
        <v-col cols="12" md="6" sm="8" class="pa-0 mt-1">
          <v-divider class="mx-4"></v-divider>
        </v-col>
      </v-row>
      <v-row
        class="pa-0 ml-4"
        justify-sm="center"
        v-for="(experience, index) in candidate.experiences"
        :key="index"
      >
        <v-col cols="12" md="6" sm="8" class="pa-0">
          <div class="pl-2 pa-0 text-caption secondary--text font-weight-light">
          sep 2019 - actualidad: 1 año y 2 meses
        </div> 
          <v-list-item class="ml-2 ma-0 pa-0">
            <v-list-item-avatar
              tile
              size="30"
              color="grey"
            ></v-list-item-avatar>
            <v-list-item-content class="pa-0">
              <v-list-item-title
                class="mb-1 text-caption primary--text font-weight-bold"
              >
                {{ experience.title.name }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="text-caption font-weight-light secondary--text"
                >{{ experience.pharmacy_name }}</v-list-item-subtitle
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </v-col> -->
    <v-row justify-sm="center">
      <v-col cols="10" md="5" sm="8" class="ma-0 pa-0 primary--text">
        <v-list-item class="primary--text ml-3">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              {{ $t("register.personal_data") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="ma-0 my-1" justify-sm="center">
      <v-col cols="12" md="5" sm="8" class="pa-0 mt-1">
        <v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="font-weight-light tertiary--text">
          <v-list-item-content class="ml-2">
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font">
                {{ $t("field.name") + ":" }}</span
              >
              <span class="primary--text font opacity">
                {{ candidate.full_name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.email") + ":" }}
              </span>
              <span class="primary--text font opacity">
                {{ candidate.user.email }}
              </span>
            </v-list-item-subtitle>

            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.phone_number") + ":" }}
              </span>
              <span class="primary--text font opacity">
                {{ candidate.phone_number }}
              </span>
            </v-list-item-subtitle>

            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.birthday") + ":" }}
              </span>
              <span class="primary--text font opacity"
                >{{ candidate.birthday_date }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.address") + ":" }}
              </span>
              <span class="primary--text font opacity"
                >{{ candidate.address.formatted }}
              </span>
            </v-list-item-subtitle>

            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.languages") + ":" }}
              </span>
              <span
                v-for="(language, index) in candidate.languages"
                :key="index"
                class="primary--text font opacity"
                >{{ index == 0 ? "" : ", " }}{{ language.language.name | trim }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row justify-sm="center">
      <v-col cols="10" md="5" sm="8" class="ma-0 pa-0 primary--text">
        <v-list-item class="primary--text ml-3">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              {{ $t("candidate.professional_data") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col cols="12" md="5" sm="8" class="pa-0 mt-1">
        <v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="font-weight-light tertiary--text">
          <v-list-item-content class="ml-2">
            <v-list-item-subtitle
              class="my-1"
              v-if="candidate.profile != null && candidate.profile.license"
            >
              <span class="text-weight-bold primary--text font"
                >{{ $t("field.license") + ":" }}
              </span>
              <span class="primary--text font opacity"
                >{{ candidate.profile.license }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="my-1"
              v-if="candidate.experience_fields.length > 0"
              ><span class="text-weight-bold primary--text font">{{
                $t("exeprience_areas.title") + ":"
              }}</span>
              <ul style="list-style-type: none">
                <li
                  v-for="(field, index) in candidate.experience_fields"
                  :key="index"
                  class="primary--text"
                >
                  <span class="primary--text font opacity">
                    {{ "" + field.name + "." }}
                  </span>
                </li>
              </ul>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="my-1"
              v-if="candidate.expertises.length > 0"
              ><span class="text-weight-bold primary--text font">{{
                $tc("specialization.title", 2) + ":"
              }}</span>
              <ul style="list-style-type: none">
                <li
                  v-for="(expertise, index) in candidate.expertises"
                  :key="index"
                  class="primary--text"
                >
                  <span class="primary--text font opacity">
                    {{ "" + expertise.name + "." }}
                  </span>
                </li>
              </ul>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="my-1"
              v-if="candidate.pharmacy_experience"
            >
              <span class="text-weight-bold primary--text font">
                {{ $t("field.pharmacy_experience") }}</span
              >
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row justify-sm="center">
      <v-col cols="10" md="5" sm="8" class="ma-0 pa-0 primary--text">
        <v-list-item class="primary--text ml-3">
          <v-list-item-content>
            <v-list-item-title class="primary--text">
              {{ $t("candidate.studies") }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
    <v-row class="ma-0" justify-sm="center">
      <v-col cols="12" md="5" sm="8" class="pa-0 mt-1">
        <v-divider class="mx-4"></v-divider>
      </v-col>
    </v-row>
    <v-row class="ma-0 mb-4" justify-sm="center">
      <v-col class="pa-0" cols="12" sm="8" md="5">
        <v-list-item class="font-weight-light tertiary--text">
          <v-list-item-content class="ml-2">
            <v-list-item-subtitle class="my-1">
              <span class="text-weight-bold primary--text font">
                {{ $t("field.title") + ":" }}</span
              >
              <span class="primary--text font opacity">
                {{ candidate.title.name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1" v-if="candidate.profile != null">
              <span class="text-weight-bold primary--text font">
                {{ $t("field.university") + ":" }}</span
              >
              <span class="primary--text font opacity">
                {{ candidate.profile.university.name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle class="my-1" v-if="candidate.profile != null">
              <span class="text-weight-bold primary--text font">
                {{ $t("field.license_state") + ":" }}</span
              >
              <span class="primary--text font opacity" v-if="candidate.profile.license_state">
                {{ candidate.profile.license_state.name }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              class="my-1"
              v-if="
                candidate.profile != null &&
                  candidate.profile.homologated_status != null
              "
            >
              <span class="text-weight-bold primary--text font">
                {{ $t("field.homologated_status") + ":" }}</span
              >
              <span class="primary--text font opacity">
                {{
                  filterHomologatedStatus(candidate.profile.homologated_status)
                }}
              </span>
            </v-list-item-subtitle>
            <v-list-item-subtitle
              v-if="candidate.educations.length > 0"
              class="my-1"
              ><span class="text-weight-bold primary--text font">{{
                $t("field.other_title") + ":"
              }}</span>
              <span class="primary--text font opacity">
                {{ candidate.educations | titleList }}
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
// Layout component
import layout from "@/components/layouts/Profile.vue";
// Modals component
import AddNotes from "@/components/modals/AddNotes.vue";
import PdfViewer from "../../components/modals/PdfViewer.vue";
import { mapGetters, mapActions } from "vuex";
import OtherFiles from "../../components/modals/OtherFiles.vue";
import VideoPlayer from "@/components/modals/VideoPlayer.vue";
import AddExpertFiles from "@/components/modals/AddExpertFiles.vue";
export default {
  components: {
    layout,
    AddNotes,
    PdfViewer,
    OtherFiles,
    VideoPlayer,
    AddExpertFiles
  },
  data() {
    return {
      renderPdf: null,
      dialog: false,
      wantDownload: false,
      pdfDialog: false,
      videoPlayer: false,
      expertFilesModal: false,
      showotherFiles: false,
      colors: ["grey", "#f39f18", "success", "error"],
      candidateStatus: null,
      mounted: false,
      status: {
        text: null,
        color: null
      },
      notes: null,
      contactInfo: false
    };
  },
  async created() {
    this.enableLoading();
    await this.getCandidate({
      candidate_id: this.$route.params.candidate_id,
      offer_id: this.$route.query.offer_id,
      userType: this.userType
    });
    await this.getStatusChoices(this.userType);
    this.getHomologatedChoices();
    try {
      await this.getOtherFiles(this.$route.params.candidate_id);
    } catch {
      this.notifyNoOtherFiles();
    }
    this.changeStatus(
      this.userType == "expert"
        ? this.candidate.expert_status
        : this.candidate.pharmacy_status
    );
    this.getExpertFiles({
      candidate_id: this.$route.params.candidate_id,
      offer_id: this.$route.query.offer_id
    });
    this.notes = this.candidate.notes;
    this.mounted = true;
    this.disableLoading();
  },
  computed: {
    ...mapGetters({ candidate: "match/candidate" }),
    ...mapGetters({ pharmacyStatus: "core/pharmacyStatusChoices" }),
    ...mapGetters({ otherFiles: "candidate/otherFiles" }),
    ...mapGetters({ expertFiles: "candidate/expertFiles" }),
    ...mapGetters({ userType: "user/type" }),
    ...mapGetters({ homologatedChoices: "core/homologatedChoices" })
  },
  methods: {
    ...mapActions({ getCandidate: "match/getCandidate" }),
    ...mapActions({ getOtherFiles: "candidate/getOtherFiles" }),
    ...mapActions({ enableLoading: "application/enableLoading" }),
    ...mapActions({ disableLoading: "application/disableLoading" }),
    ...mapActions({ getExpertFiles: "candidate/getExpertFiles" }),
    ...mapActions({ getHomologatedChoices: "core/getHomologatedChoices" }),
    ...mapActions({
      getStatusChoices: "core/getStatusChoices"
    }),
    modalAction(payload) {
      this.dialog = !this.dialog;
      let text = payload.text;
      if (payload.update) {
        try {
          this.$store.dispatch("offer/addPharmacyNotes", {
            offer_id: this.$route.query.offer_id,
            notes: text,
            candidate_id: this.candidate.id
          });
          this.$notify({
            title: this.$i18n.tc("notification.add_notes", 1),
            type: "success"
          });
        } catch {
          this.notes = text;
          this.$notify({
            title: this.$i18n.tc("notification.add_notes", 2),
            type: "error"
          });
        }
      }
    },
    openCurriculumModal() {
      this.wantDownload = false;
      this.renderPdf = this.candidate.curriculum;
      this.pdfDialog = true;
    },
    openExpertFilesModal() {
      this.wantDownload = true;
      this.renderPdf = this.expertFiles[0].file;
      this.pdfDialog = true;
    },
    closePdfViewer() {
      this.renderPdf = null;
      this.pdfDialog = false;
    },
    toggleModal() {
      this.showotherFiles = !this.showotherFiles;
    },
    toggleVideoPlayer() {
      this.videoPlayer = !this.videoPlayer;
    },
    toggleExpertFiles() {
      this.expertFilesModal = !this.expertFilesModal;
    },
    markAsSelected() {
      this.$store.dispatch("offer/changeExpertOfferStatus", {
        offer_id: this.$route.query.offer_id,
        status: "ES",
        candidate_id: this.candidate.id
      });
    },
    filterHomologatedStatus(choice) {
      return this.homologatedChoices.find(f => f[0] == choice)[1];
    },
    updateStatus(e) {
      this.changeStatus(e);
      let action =
        this.userType == "expert"
          ? "offer/changeExpertOfferStatus"
          : "offer/changePharmacyOfferStatus";
      this.$store
        .dispatch(action, {
          offer_id: this.$route.query.offer_id,
          status: e,
          candidate_id: this.candidate.id
        })
        .then(response => {
          if (response.status == 200) {
            // location.reload();
          }
        });
    },
    notifyNoOtherFiles() {
      this.$notify({
        title: this.$i18n.tc("notification.other_files_error"),
        type: "error"
      });
    },
    changeStatus(status) {
      this.status.text = this.pharmacyStatus.filter(
        elm => elm[0] == status
      )[0][1];
      this.status.color = this.colors[
        this.pharmacyStatus.findIndex(elm => elm[0] == status)
      ];
    }
  }
};
</script>
<style scoped>
.v-btn:not(.v-btn--round).v-size--default {
  height: 30px;
  min-width: 30px;
}
.v-divider {
  border-color: #718f94 !important;
}
.v-menu__content {
  box-shadow: none !important;
}
.v-list-item {
  min-height: 35px;
}
.font {
  font-size: 13px;
}
.opacity {
  opacity: 0.6;
}
.v-list-item__subtitle {
  white-space: normal !important;
}
</style>
